<template>
  <div>
    <h2>Available travel orders</h2>
    <ul v-if="availableRides.length">
      <li v-for="ride in availableRides" :key="ride.id">
        <p>Ride #{{ ride.id }}</p>
        <p>From: {{ ride.start_name_location }}</p>
        <p>Where: {{ ride.end_name_location }}</p>
        <!-- Кнопки действий -->
        <button @click="acceptRide(ride.id)">Take an order</button>
        <button @click="delayedAcceptance(ride.id)">I'll be there in 15 minutes</button>
        <button @click="showDetails(ride.id)">More details</button>
      </li>
    </ul>
    <p v-else>No travel bookings available.</p>
  </div>
</template>

<script>
import API from '@/api';
import router from '@/router';

export default {
  name: 'AvailableRideslist',
  data() {
    return {
      availableRides: []
    };
  },
  mounted() {
    this.fetchAvailableRides();
  },
  methods: {
    async fetchAvailableRides() {
      try {
        const response = await API.get('api/drivers/rides/available/');
        this.availableRides = response.data;
      } catch (error) {
        console.error('Ошибка при получении доступных заказов поездок:', error);
      }
    },
    async acceptRide(rideId) {
      // Логика для принятия заказа
      console.log(`Заказ ${rideId} принят`);
      try {
        await API.post(`api/rides/accept/${rideId}/`);
        console.log(`Заказ ${rideId} принят`);
        // Перенаправление на страницу активного заказа
        router.push('/driver/active');
      } catch (error) {
        console.error('Ошибка при принятии заказа:', error);
      }
    },
    delayedAcceptance(rideId) {
      // Логика для отложенного принятия заказа
      console.log(`Заказ ${rideId} будет выполнен через 15 минут`);
      // Здесь должен быть API запрос на сервер для установки статуса
    },
    showDetails(rideId) {
      // Логика для отображения деталей заказа
      console.log(`Показать детали заказа ${rideId}`);
      // Можно перенаправить на страницу с деталями заказа или открыть модальное окно
    }
  }
};
</script>