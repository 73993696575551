import { createRouter, createWebHistory } from 'vue-router';

// Импорт компонентов
import Homepage from '@/views/pages/Homepage.vue';
import Registration from '@/views/user/Registration.vue';
import Login from '@/views/user/Login.vue';
import Profile from '@/views/user/Profile.vue';
import DriverList from '@/views/driver/DriverList.vue';
import RideRequests from '@/views/driver/RideRequests.vue';
import ActivateRide from '@/views/driver/ActivateRide.vue';
import ActiveRide from '@/views/driver/ActiveRide.vue';
import CompletedRide from '@/views/driver/CompletedRide.vue';
import RideHistory from '@/views/driver/RideHistory.vue';
import RideCompletion from '@/views/driver/RideCompletion.vue';
import CreateRequest from '@/views/ride/CreateRequest.vue';
import RideWaiting from '@/views/ride/RideWaiting.vue';
//import RideCompletion from '@/views/ride/RideCompletion.vue';
import FutureTaxiorder from '@/views/ride/FutureTaxiOrder.vue';
import ActiveRidesList from '@/views/ride/ActiveRidesList.vue'; 
import RideDetails from '@/views/ride/RideDetails.vue'; 
import ActiveRidePsng from '@/views/ride/ActiveRide.vue'; 
import AvailableRideslist from '@/views/driver/AvailableRidesList.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Homepage
  },
    {
    path: '/register',
    name: 'UserRegistration',
    component: Registration
  },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/drivers',
      name: 'DriverList',
      component: DriverList
    },
    {
      path: '/driver/requests',
      name: 'RideRequests',
      component: RideRequests
    },
    {
      path: '/ride/schedule_request',
      name: 'FutureTaxiorder',
      component: FutureTaxiorder
    },
    {
      path: '/driver/activate',
      name: 'ActivateRide',
      component: ActivateRide
    },
    {
      path: '/driver/active',
      name: 'ActiveRide',
      component: ActiveRide
    },
    {
      path: '/driver/completed',
      name: 'CompletedRide',
      component: CompletedRide
    },
    {
      path: '/driver/history',
      name: 'RideHistory',
      component: RideHistory
    },
    {
      path: '/ride/create',
      name: 'CreateRequest',
      component: CreateRequest
    },
    {
      path: '/ride/waiting',
      name: 'RideWaiting',
      component: RideWaiting
    },
    {
      path: '/ride/completion',
      name: 'RideCompletion',
      component: RideCompletion
    },
      {
      path: '/active-rides',
      name: 'ActiveRides',
      component: ActiveRidesList
    },
      {
      path: '/active-ride-png',
      name: 'ActiveRidePsng',
      component: ActiveRidePsng
    },
      {
      path: '/driver/available-rides-list',
      name: 'AvailableRideslist',
      component: AvailableRideslist
    },
    {
      path: '/rides/:rideId',
      name: 'RideDetails',
      component: RideDetails,
      props: true // Включает передачу параметров маршрута в компонент
    }
  // Остальные маршруты
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;


// import Vue from 'vue';
// import Router from 'vue-router';

// // Импорт компонентов
// import Registration from '@/views/user/Registration.vue';
// import Login from '@/views/user/Login.vue';
// import Profile from '@/views/user/Profile.vue';
// import DriverList from '@/views/driver/DriverList.vue';
// import RideRequests from '@/views/driver/RideRequests.vue';
// import ActivateRide from '@/views/driver/ActivateRide.vue';
// import ActiveRide from '@/views/driver/ActiveRide.vue';
// import CompletedRide from '@/views/driver/CompletedRide.vue';
// import RideHistory from '@/views/driver/RideHistory.vue';
// import CreateRequest from '@/views/ride/CreateRequest.vue';
// import RideWaiting from '@/views/ride/RideWaiting.vue';
// import RideCompletion from '@/views/ride/RideCompletion.vue';

// Vue.use(Router);

// export default new Router({
//   routes: [
//     {
//       path: '/register',
//       name: 'Registration',
//       component: Registration
//     },
//     {
//       path: '/login',
//       name: 'Login',
//       component: Login
//     },
//     {
//       path: '/profile',
//       name: 'Profile',
//       component: Profile
//     },
//     {
//       path: '/drivers',
//       name: 'DriverList',
//       component: DriverList
//     },
//     {
//       path: '/driver/requests',
//       name: 'RideRequests',
//       component: RideRequests
//     },
//     {
//       path: '/driver/activate',
//       name: 'ActivateRide',
//       component: ActivateRide
//     },
//     {
//       path: '/driver/active',
//       name: 'ActiveRide',
//       component: ActiveRide
//     },
//     {
//       path: '/driver/completed',
//       name: 'CompletedRide',
//       component: CompletedRide
//     },
//     {
//       path: '/driver/history',
//       name: 'RideHistory',
//       component: RideHistory
//     },
//     {
//       path: '/ride/create',
//       name: 'CreateRequest',
//       component: CreateRequest
//     },
//     {
//       path: '/ride/waiting',
//       name: 'RideWaiting',
//       component: RideWaiting
//     },
//     {
//       path: '/ride/completion',
//       name: 'RideCompletion',
//       component: RideCompletion
//     }
//     // Можно добавить дополнительные маршруты здесь
//   ]
// });

// // views/
// // ├─ user/
// // │  ├─ Registration.vue       # Страница регистрации пользователя
// // │  ├─ Login.vue              # Страница авторизации пользователя
// // │  ├─ Profile.vue            # Страница профиля пользователя
// // ├─ driver/
// // │  ├─ DriverList.vue         # Просмотр списка водителей
// // │  ├─ RideRequests.vue       # Страница запросов поездок для водителей
// // │  ├─ ActivateRide.vue       # Страница активации поездки водителем
// // │  ├─ ActiveRide.vue         # Страница активной поездки водителя
// // │  ├─ CompletedRide.vue      # Страница завершенной поездки водителя
// // │  ├─ RideHistory.vue        # Страница истории поездок водителя
// // ├─ ride/
// // │  ├─ CreateRequest.vue      # Страница создания запроса на поездку
// // │  ├─ RideWaiting.vue        # Страница ожидания поездки
// // │  ├─ RideCompletion.vue     # Страница завершения поездки
