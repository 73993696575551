<template>
	<div>
		<img src="assets/theme/big_logo_logo.webp" />
		<img :src="src" />
	</div>
  <div v-if="!isAuthenticated">
    <h1>You are not logged in, please log in </h1>
  </div>
  <div v-if="isAuthenticated">
  <h1>Book a trip ! <a href="/ride/create" class="">Book a trip</a> </h1>
  </div>
</template>

<script>
import API from '@/api';
import { mapGetters } from 'vuex';



export default {
  name: 'UserRegistration',
  data() {
    return {
      user: {
        username: '',
        password: '',
        src: 'assets/theme/big_logo_logo.webp',
      },
      registrationSuccess: false
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isDriver']), // Геттер из Vuex
  },

  methods: {
    register() {
      console.log('Отправляемые данные:', this.user);
      API.post('/api/register/', this.user)
        .then(response => {
          localStorage.setItem('jwt', response.data.token);
          this.registrationSuccess = true;
        })
        .catch(error => {
          console.error('Ошибка регистрации', error);
        });
    }
  }
};
</script>