<template>
    <button v-if="!showSidebar" @click="toggleSidebar" class="open-sidebar-btn">
      <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path d="m12 8h15m-22 8h22m-22 8h22m-22-13 3-3-3-3" stroke="#535358" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
    </button>

  <div class="sidebar" v-if="showSidebar" >
    <button @click="toggleSidebar" class="sidebar-toggle">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="32" viewBox="0 0 32 32" width="32"><path d="m8.5 23.5 15-15m0 15-15-15" stroke="#535358" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
    </button>
    <div class="sidebar-content">
      <ul class="sidebar-list">
        
        <li v-for="item in filteredItems" :key="item.id">
          <router-link :to="item.href">{{ item.title }}</router-link>
        </li>
        
        <li v-if="!isAuthenticated">
          <a href="/" class="">NOT AUTHORIZED</a>
          <a href="/login" class="">Log-in</a>
        </li>
        <li v-if="isAuthenticated">
          <a href="/" class="">AUTHORIZED</a> <br>
          <button @click="logout">Exit</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent,  } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'SidebarUi',

  data() {
    return {
      showSidebar: false,
      items: [
                {
          id: 1,
          title: 'Home',
          href: '/',
          forAuthenticated: true, // Доступно всем авторизованным пользователям
          forDrivers: false      // Не специфично для водителей
        },
        {
          id: 2,
          title: 'Profile',
          href: '/profile',
          forAuthenticated: true,
          forDrivers: false
        },
        {
          id: 3,
          title: 'Settings',
          href: '/settings',
          forAuthenticated: true,
          forDrivers: false
        },
        {
          id: 4,
          title: 'Plan a trip for later',
          href: '/ride/schedule_request',
          forAuthenticated: true,
          forDrivers: false
        },


        {
          id: 5,
          title: "Registration",
          href: "/register",
          forAuthenticated: false,
          forDrivers: false
        },
        {
          id: 6,
          title: "Login",
          href: "/login",
          forAuthenticated: false,
          forDrivers: false
        },
  //    {
  //      id: 7,
  //      title: "-Drivers",
  //      href: "/drivers",
  //      forAuthenticated: true,
  //      forDrivers: false
  //    },
  //    {
  //      id: 8,
  //      title: "Driver requests",
  //      href: "/driver/requests",
  //      forAuthenticated: true,
  //      forDrivers: true
  //    },
  //    {
  //      id: 9,
  //      title: "-Activate trip",
  //      href: "/driver/activate",
  //      forAuthenticated: true,
  //      forDrivers: true
  //    },
        {
          id: 10,
          title: "Active trip",
          href: "/driver/active",
          forAuthenticated: true,
          forDrivers: true
        },
  //    {
  //      id: 11,
  //      title: "-Completed trip",
  //      href: "/driver/completed",
  //      forAuthenticated: true,
  //      forDrivers: true
  //    },
  //    {
  //      id: 12,
  //      title: "-Travel history",
  //      href: "/driver/history",
  //      forAuthenticated: true,
  //      forDrivers: true
  //    },
        {
          id: 13,
          title: "Create a trip request",
          href: "/ride/create",
          forAuthenticated: true,
          forDrivers: false
        },
 //     {
 //       id: 14,
 //       title: "-Waiting for the trip",
 //       href: "/ride/waiting",
 //       forAuthenticated: true,
 //       forDrivers: false
 //     },
  //    {
  //      id: 15,
  //      title: "-End of the trip",
  //      href: "/ride/completion",
  //      forAuthenticated: true,
  //    },
  //    {
  //      id: 16,
  //      title: "-LIST OF ACTIVE TRIPS",
  //      href: "/active-rides",
  //      forAuthenticated: true,
  //    },
        {
          id: 19,
          title: "Active trip",
          href: "/active-ride-png",
          forAuthenticated: true,
          forDrivers: false
        },
  //    {
  //    id: 17,
  //    title: "-End of the trip",
  //    href: "/ride/completion",
  //    forAuthenticated: true,
  //  },

        {
          id: 18,
          title: "Travel Requests",
          href: "/driver/available-rides-list",
          forAuthenticated: true,
          forDrivers: true
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'isDriver']), // Геттер из Vuex

    filteredItemsOld() {
      if (this.isAuthenticated) {
        return this.items.filter(item => {
          if (this.isDriver) {
            // Показать определенные элементы для водителей
            return item.title !== 'Регистрация' && item.title !== 'Вход';
          } else {
            // Показать общие элементы для авторизованных пользователей
            return item.title !== 'Регистрация' && item.title !== 'Вход' && !item.title.includes('Водитель');
          }
        });
      } else {
        // Показать элементы только для неавторизованных пользователей
        return this.items.filter(item => item.title === 'Регистрация' || item.title === 'Вход');
      }
    },

    filteredItems() {
      // Возвращаем элементы в зависимости от статуса пользователя
      return this.items.filter(item => {
        if (!this.isAuthenticated) {
          // Для неавторизованных пользователей
          return !item.forAuthenticated;
        } else if (this.isDriver) {
          // Для водителей
          return item.forDrivers !== false; // Возвращает true для элементов, доступных водителям
        } else {
          // Для авторизованных пользователей, не являющихся водителями
          return item.forAuthenticated !== false && item.forDrivers !== true; // Исключает элементы, специфичные только для водителей
        }
      });
    }
  },


  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    handleSwipe(e) {
      // Проверка направления свайпа
      if (e.deltaX < 0) { // Свайп влево
        this.showSidebar = false;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login'); // Перенаправление на страницу входа
    },
    
  },

  mounted() {
    // Добавление обработчика свайпа
    // this.$refs.sidebar.addEventListener('swipe', this.handleSwipe);
    if (this.$refs.sidebar) {
      this.$refs.sidebar.addEventListener('swipe', this.handleSwipe);
      // const hammer = new Hammer(this.$refs.sidebar);
      // hammer.on('swipe', this.handleSwipe);
    }
    //document.addEventListener('click', this.handleClickOutside);
    
  },

  beforeUnmount() {
    // Удаление обработчика свайпа при удалении компонента
    // this.$refs.sidebar.removeEventListener('swipe', this.handleSwipe);
    if (this.$refs.sidebar) {
      this.$refs.sidebar.removeEventListener('swipe', this.handleSwipe);
      //const hammer = new Hammer(this.$refs.sidebar);
      //hammer.off('swipe', this.handleSwipe);
    }
    //document.removeEventListener('click', this.handleClickOutside);
  }
});
</script>






<style>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  width: 250px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &.hidden {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }

  &.is-swiping {
    transition: none;
  }
}



.sidebar-content {
  padding: 1rem;
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-list li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.sidebar-list li a {
  color: #333;
  text-decoration: none;
}

.sidebar-list li a:hover {
  color: #000;
}

@media (max-width: 992px) {
  .sidebar {
    width: 100%;
  }
}

.open-sidebar-btn {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1010; /* Повыше, чтобы быть поверх других элементов */
  /* Дополнительные стили для кнопки */
}

</style>