<template>
  <div>
    <h2>Trip details</h2>
    <!-- Display trip details -->
    <div id="map" class="map-container"></div>
  </div>
</template>

<script>
import L from 'leaflet';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      map: null,
      driverMarker: null
    };
  },
  computed: {
    ...mapState(['rideDetails', 'driverLocation'])
  },
  methods: {
    ...mapActions(['fetchRideDetails', 'fetchDriverLocation']),

    initMap() {
      this.map = L.map('map').setView([34.0522, -118.2437], 13); // Примерные координаты центра карты
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);
    },

    updateDriverMarker() {
      const { lat, lng } = this.driverLocation;
      if (this.driverMarker) {
        this.driverMarker.setLatLng([lat, lng]);
      } else {
        this.driverMarker = L.marker([lat, lng]).addTo(this.map);
      }
      this.map.panTo([lat, lng]);
    }
  },
  mounted() {
    this.initMap();
    const rideId = this.$route.params.rideId; // Получение ID поездки из маршрута
    this.fetchDriverLocation(rideId);
    setInterval(() => {
      this.fetchDriverLocation(rideId);
      this.updateDriverMarker();
    }, 10000); // Обновление каждые 10 секунд
  }

};
</script>


<style>
.map-container {
  height: 400px; /* Установите нужный размер */
}
</style>