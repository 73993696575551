<template>
  <div>
    <h2>Текущая поездка</h2>
    <p>Откуда: {{ startLocationName }}</p>
    <p>Куда: {{ endLocationName }}</p>
    <p>rideStarted: {{ rideStarted }}</p>
    <p>rideInProgress: {{ rideInProgress }}</p>
    <p>rideId: {{ rideId }}</p>
    <p>rideStatus: {{ rideStatus }}</p>
    <div id="driver-map" class="map-container"></div>
    <button v-if="!rideStarted" @click="notifyArrival">I arrived to the passenger</button>
    <button v-if="rideStarted && !rideInProgress" @click="startRide">We went with a passenger</button>
    <button v-if="rideInProgress" @click="endRide">Complete order</button>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import API from '@/api';
import store from '@/store';
import router from '@/router';



export default {
  name: 'ActiveRide',
  data() {
    return {
      map: null,
      driverMarker: null,
      startLocation: null,
      endLocation: null,
      startLocationName: '',
      endLocationName: '',
      rideStarted: false,
      rideInProgress: false,
      rideId: null,
      rideStatus: '',
      routingControl: null,
      passengerLocation: [34.1462632438538, -118.25818888097028],
    };
  },
  mounted() {
    if (store.getters.isDriver) {
      this.initMap();
      this.getCurrentRide();
      this.updateDriverLocation();
      this.intervalId = setInterval(this.updateDriverLocation, 15000);
    } else {
      // Обработка ситуации, когда пользователь не является водителем
      // todo надо убрать этот текст, т.к. я пока ещё не настроил
      this.getCurrentRide();
      this.initMap();
      this.intervalId = setInterval(this.updateDriverLocation, 5000);
    }
  },
  methods: {
    initMap() {
      this.map = L.map('driver-map').setView([34.0522, -118.2437], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);
    },
    async notifyArrival() {
      try {
        await API.post(`api/rides/${this.rideId}/arrived/`);
        this.rideStarted = true;
        this.drawRouteToEndLocation();
      } catch (error) {
        console.error('Ошибка при уведомлении о прибытии:', error);
      }
    },
    drawRouteToEndLocation() {
      if (this.routingControl) {
        this.map.removeControl(this.routingControl);
      }
      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(this.driverMarker.getLatLng()),
          L.latLng(this.endLocation)
        ],
        routeWhileDragging: true,
        show: false, // Опционально скрыть инструкции
      }).addTo(this.map);
    },
    drawRouteToEndLocation_2() {
      // Нарисовать маршрут от места начала поездки до конечного пункта
      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(this.startLocation),
          L.latLng(this.endLocation)
        ],
        routeWhileDragging: false,
        show: false, // Опционально скрыть инструкции
      }).addTo(this.map);
    },
    async getCurrentRideOLD() {
      try {
        const response = await API.get('api/driver/current-ride/');
        // this.startLocation = response.data.start_location;
        this.startLocation = JSON.parse(response.data.start_location);
        this.startLocationName = response.data.start_name_location;
        this.endLocationName = response.data.end_name_location;
        this.rideId = response.data.ride_id;
        this.updateRoute();
      } catch (error) {
        console.error('Ошибка при получении текущей поездки:', error);
      }
    },
    async getCurrentRide() {
      try {
        const response = await API.get('api/driver/current-ride/');
        this.startLocation = JSON.parse(response.data.start_location);
        this.endLocation = JSON.parse(response.data.end_location);
        this.startLocationName = response.data.start_name_location;
        this.endLocationName = response.data.end_name_location;
        this.rideId = response.data.ride_id;
        this.rideStatus = response.data.status; // Получаем статус поездки

        // В зависимости от статуса, обновляем состояние компонента и карты
        switch(this.rideStatus) {
          case 'accepted':
            // Если статус 'accepted', маршрут до startLocation
            this.rideStarted = true;
            this.drawRoute(this.driverMarker.getLatLng(), this.startLocation);
            break;
          case 'arrived':
          case 'waiting_for_passenger':
            this.rideStarted = true;
            this.rideInProgress = false; 
            this.drawRoute(this.driverMarker.getLatLng(), this.endLocation);
            break;
          case 'in_progress':
          case 'travelling':
            // Для всех этих статусов, маршрут до endLocation
            this.rideStarted = true;
            this.rideInProgress = true; // Можно использовать для отображения кнопки "Заказ завершён"
            this.drawRoute(this.driverMarker.getLatLng(), this.endLocation);
            break;
        }
      } catch (error) {
        console.error('Ошибка при получении текущей поездки:', error);
      }
    },

    placeMarker(location, title) {
      // Поместить маркер на карте
      if (this.driverMarker) {
        this.map.removeLayer(this.driverMarker);
      }
      this.driverMarker = L.marker(location, { title: title }).addTo(this.map);
      this.map.setView(location, 13);
    },
    drawInitialRoute() {
      // Нарисовать маршрут от текущего местоположения до места начала поездки
      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(this.driverMarker.getLatLng()),
          L.latLng(this.startLocation)
        ],
        routeWhileDragging: false,
        show: false, // Опционально скрыть инструкции
      }).addTo(this.map);
    },
    updateRoute() {
      if (this.driverMarker) {
        L.Routing.control({
          waypoints: [
            L.latLng(this.driverMarker.getLatLng()),
            L.latLng(this.startLocation)
          ],
          routeWhileDragging: true,
          show: false, // Скрытие панели с инструкциями
          //lineOptions: {
          //  styles: [{ color: '#555', weight: 4 }]
          //}
        }).addTo(this.map);
      }
    },
    async updateDriverLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async position => {
          const { latitude, longitude } = position.coords;
          if (this.driverMarker) {
            this.driverMarker.setLatLng([latitude, longitude]);
          } else {
            this.driverMarker = L.marker([latitude, longitude], { title: 'Водитель' }).addTo(this.map);
          }
          this.map.panTo([latitude, longitude]);
          await API.patch('api/driver/update-location/', {
            current_location: `${latitude}, ${longitude}`,
            current_location_lat: latitude,
            current_location_long: longitude
          });
          this.updateRoute(); // Обновляем маршрут с новыми координатами водителя
        }, error => {
          console.error('Ошибка получения геолокации:', error);
        });
      } else {
        console.error('Геолокация не поддерживается этим браузером.');
      }
    },

    drawRoute_q() {
      if (this.routingControl) {
        this.map.removeControl(this.routingControl);
      }
      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(this.driverLocation),
          L.latLng(this.startLocation)
        ],
        routeWhileDragging: true
      }).addTo(this.map);
    },

    drawRoute(start, end) {
      if (this.routingControl) {
        this.map.removeControl(this.routingControl);
      }
      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(start),
          L.latLng(end)
        ],
        routeWhileDragging: false,
        show: false, // Скрыть панель с инструкциями
      }).addTo(this.map);
    },
    async startRide() {
      try {
        //await API.post(`api/start-ride/${this.rideId}`);
        await API.post(`api/rides/${this.rideId}/start/`);
        this.rideInProgress = true;
      } catch (error) {
        console.error('Ошибка при начале поездки:', error);
      }
    },
    async endRide() {
      try {
        await API.post(`api/rides/${this.rideId}/complete/`);
        router.push('/driver/completed'); // Переход на главную страницу
      } catch (error) {
        console.error('Ошибка при завершении поездки:', error);
      }
    },
    async endRide2() {
      try {
        // Замените '<int:ride_id>' на фактический ID поездки из данных компонента
        const rideId = this.rideId;
        const response = await API.post(`api/rides/${rideId}/complete/`);

        // Проверка успешного ответа от сервера (опционально)
        if (response && response.status === 200) {
          // Переход на страницу с результатами поездки
          this.$router.push('/driver/completed');
        } else {
          // Обработка ошибки или неуспешного статуса завершения поездки
          console.error('Ошибка при завершении поездки:', response);
        }
      } catch (error) {
        console.error('Ошибка при завершении поездки:', error);
      }
    },
    beforeUnmount() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  } 
};
</script>

<style>
.map-container {
  height: 400px; /* Установите необходимый размер */
}
</style>