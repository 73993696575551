import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
//import Sidebar from './components/Sidebar.vue';


store.dispatch('checkToken');
// Проверка наличия токена в localStorage и установка его в Vuex
const token = localStorage.getItem('jwt');
if (token) {
  store.commit('setAuthToken', token);
}


// createApp(App).mount('#app')
createApp(App)
  .use(router)
  .use(store)
  .mount('#app');