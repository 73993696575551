import { createStore } from 'vuex';
import axios from 'axios'; // Убедитесь, что axios установлен
import API from '@/api';

export default createStore({
  state: {
    authToken: null, // Состояние для хранения токена
    authStatus: '',
    isDriver: false ,
    activeRides: [], // Состояние для хранения активных поездок
    rideDetails: {},
    driverLocation: {}
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
      state.authStatus = 'success';
      console.log('а токен:', token);
    },

    setDriverStatus(state, is_driver) {
      state.isDriver = is_driver;
      console.log('Записал state.isDriver:', state.isDriver);
      console.log('а пришел isDriver:', is_driver);
    },

    clearAuthToken(state) {
      state.authToken = null; // Мутация для очистки токена
      state.authStatus = 'failed'; 
      state.isDriver = false;
    },
    setAuthStatus(state, { status, is_driver }) {
      state.authStatus = status;
      state.isDriver = is_driver;
    },
    setActiveRides(state, rides) {
      state.activeRides = rides;
    },
    setRideDetails(state, details) {
      state.rideDetails = details;
    },
    setDriverLocation(state, location) {
      state.driverLocation = location;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('https://api.clusterhack.dev/api/login/', credentials);
        const token = response.data.access; 
        const is_driver = response.data.is_driver; 
        commit('setAuthToken', token ); 
        commit('setDriverStatus', is_driver ); 
        localStorage.setItem('jwt', token); // Сохраняем один и тот же токен
      } catch (error) {
        console.error('Ошибка входа', error);
      }
    },
    logout({ commit }) {
      commit('clearAuthToken');
      localStorage.removeItem('jwt');
    },
    async checkToken({ commit }) {
      const tokenJwt = localStorage.getItem('jwt');
      console.log('Отправка запроса на валидацию jwt: ', tokenJwt); 
      if (tokenJwt) {
        console.log('jwt существует '); 
        try {
          // Отправляем запрос на сервер для проверки токена
          // const response = await axios.post('http://127.0.0.1:8000/api/token/validate', {token: tokenJwt},{
          //   headers: {
          //     'Authorization': `Bearer ${tokenJwt}`
          //   },
          const response = await axios.post('https://api.clusterhack.dev/api/token/validate', {
              token: tokenJwt,
              // Дополнительные параметры
              // name: 'John Doe',
            }, {
              headers: {
                'Authorization': `Bearer ${tokenJwt}`
                // 'X-My-Header': 'My Value',
              }
          // const response = await axios.post('http://127.0.0.1:8000/api/token/validate', {
          //     headers: {
          //       'Authorization': `Bearer ${tokenJwt}`
          //     }
          });
          // Если сервер подтвердил действительность токена
          if (response.data.valid) {
            const is_driver = response.data.is_driver;
            console.log(' статус водителя мы получили is_driver : ', is_driver); 
            commit('setAuthToken', tokenJwt ); 
            commit('setDriverStatus', is_driver ); 
            console.log('сервер подтвердил действительность токена '); 
          } else {
            commit('clearAuthToken');
            localStorage.removeItem('jwt');
            console.log('сервер НЕ подтвердил действительность токена, УДАЛИТЬ '); 
            console.log('Ошибка от сервера: ', response.data); 
          }
        } catch (error) {
          // Обработка ошибок (например, если токен истек или недействителен)
          console.log('Не смог выполнить запрос а значит, УДАЛИТЬ '); 
          console.log('tokenJwt: ', tokenJwt); 

          commit('clearAuthToken');
          localStorage.removeItem('jwt');
          console.log('Не смог выполнить запрос а значит, УДАЛИТЬ '); 
          console.log('Ошибка: ', error); 
          
        }
      } else {
        console.log('jwt НЕ существует, удалить ');
        commit('clearAuthToken');
      }
    },
    async fetchActiveRides({ commit }) {
      try {
        const response = await API.get('https://api.clusterhack.dev/api/user/rides/active/');
        commit('setActiveRides', response.data); // предполагая, что у вас есть мутация setActiveRides
      } catch (error) {
        console.error('Ошибка при получении активных поездок:', error);
      }
    },
    async fetchRideDetails({ commit }, rideId) {
      try {
        const response = await axios.get(`/api/rides/${rideId}/`);
        commit('setRideDetails', response.data);
      } catch (error) {
        console.error('Ошибка при получении деталей поездки:', error);
      }
    },
    async fetchDriverLocation({ commit }, rideId) {
      try {
        const response = await axios.get(`/api/rides/${rideId}/driver-location/`);
        commit('setDriverLocation', response.data);
      } catch (error) {
        console.error('Ошибка при получении местоположения водителя:', error);
      }
    }
  },
  getters: {
    isAuthenticated: state => {
    console.log('Текущий токен:', state.authToken); // Показывает текущий токен
    console.log('Текущий статус:', state.authStatus); // Показывает текущий токен
    if (!state.authToken) {
      let tokenJwt = localStorage.getItem('jwt'); // Получение токена из localStorage, если он не в Vuex
      if (tokenJwt) {
        // commit('setAuthToken', tokenJwt);
        //this.setAuthToken('success', tokenJwt);

      }
    }
    console.log('Текущий токен:', state.authToken); // Показывает текущий токен
    console.log('Текущий статус:', state.authStatus); // Показывает текущий токен
    return !!state.authToken;
  
     // !!state.authToken // Геттер для проверки аутентификации
    },
    isDriver: state => state.isDriver // Геттер для определения, является ли пользователь водителем
  },

});