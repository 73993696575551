<template>
  <div>
    <h2>Active trips</h2>
    <ul v-if="activeRides.length">
      <li v-for="ride in activeRides" :key="ride.id">
        Ride #{{ ride.id }} - {{ ride.description }}
        <!-- Additional trip details -->
      </li>
    </ul>
    <p v-else>No active trips.</p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['activeRides'])
  },
  methods: {
    ...mapActions(['fetchActiveRides'])
  },
  mounted() {
    this.fetchActiveRides();
  }
};
</script>