<template>
  <div>
    <h2>Your active trip</h2>
    <div id="map" class="map-container"></div>
  </div>
</template>

<script>
import L from 'leaflet';
import API from '@/api';

export default {
  name: 'ActiveRidePsng',
  data() {
    return {
      map: null,
      driverMarker: null,
      intervalId: null
    };
  },
  mounted() {
    this.initMap();
    this.updateDriverLocation();
    this.intervalId = setInterval(this.updateDriverLocation, 10000); // Обновление каждые 10 секунд
  },
  methods: {
    initMap() {
      // Инициализация карты
      this.map = L.map('map').setView([34.0522, -118.2437], 13); // Примерные координаты
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);
    },
    async updateDriverLocation() {
      // Запрос местоположения водителя с сервера
      try {
        const response = await API.get('api/ride/active/driver-location/');
        const { lat, lng } = response.data; // Предполагается, что сервер возвращает { lat, lng }
        if (this.driverMarker) {
          this.driverMarker.setLatLng([lat, lng]);
        } else {
          this.driverMarker = L.marker([lat, lng]).addTo(this.map);
        }
        this.map.panTo([lat, lng]);
      } catch (error) {
        console.error('Ошибка получения местоположения водителя:', error);
      }
    }
  },
  beforeUnmount() {
    // Очистка интервала
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
</script>

<style>
.map-container {
  height: 400px; /* Установите необходимый размер */
}
</style>