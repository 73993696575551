<template>
  <div class="completed-ride">
    <h1>Completed trip</h1>
    <!-- Completed trip details -->
    <p>From: {{ rideDetails.start_location_name }}</p>
    <p>To: {{ rideDetails.end_location_name }}</p>
    <p>Price: {{ rideDetails.cost }}</p>
    <p>Travel time: {{ rideDetails.duration }}</p>
    <!-- Button to return to available trips -->
    <button @click="backToAvailableRides">New trips</button>
  </div>
</template>

<script>
import API from '@/api'; // Импорт экземпляра API

export default {
  name: 'CompletedRide',
  data() {
    return {
      rideDetails: {}, // Пустой объект для хранения деталей поездки
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await API.get('api/driver/rides/last-summary/');
        this.rideDetails = response.data; // Сохранение полученных деталей поездки в состояние компонента
      } catch (error) {
        console.error('Ошибка при получении деталей последней завершенной поездки:', error);
      }
    },
    backToAvailableRides() {
      this.$router.push('/driver/available-rides'); // Переход к списку доступных поездок
    }
  },
  mounted() {
    this.fetchData(); // Вызов метода для получения данных при монтировании компонента
  }
};
</script>

<style>
/* Стили компонента */
</style>
