<template>
    <Sidebar />
    
    <Nav />
    <router-view/>
    <Footer />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Sidebar from './components/Sidebar.vue';
// import Header from './components/Header.vue';
import Nav from './components/Nav.vue';
import Footer from './components/Footer.vue';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'App',
  components: {
    // Header,
    Nav,
    Sidebar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 3px;
}


body {
  font-family: 'Open Sans', sans-serif;
}
</style>



