<template>
  <div>
    <nav>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About Us</router-link></li>
        <!-- Additional menu items -->
      </ul>
    </nav>
    <h1>Component title</h1>
    <!-- Component Content -->
  </div>
</template>

<script>
import API from '@/api'; // Импорт экземпляра API

export default {
  name: 'RideRequests',
  data() {
    return {
      // данные компонента
    };
  },
  methods: {
    fetchData() {
      API.get('your-endpoint')
        .then(response => {
          // обработка данных
          this.someData = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style>
/* Стили компонента */
</style>