<template>
  <div>
    <h1>Registration</h1>
    <form @submit.prevent="register">
      <input type="text" v-model="user.username" placeholder="Username">
      <input type="password" v-model="user.password" placeholder="Password">
      <button type="submit">Registration</button>
    </form>
  </div>
</template>

<script>
// import API from '@/api'; // Путь к файлу api.js
import API from '@/api';

export default {
  name: 'UserProfile',
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    register() {
      API.post('api/register/', this.user)
        .then(response => {
          // Предполагается, что API возвращает JWT в ответе
          localStorage.setItem('jwt', response.data.token);
          console.log('Успешная регистрация', response);
          // Дальнейшие действия после регистрации
        })
        .catch(error => {
          console.error('Ошибка регистрации', error);
        });
    }
  }
};
</script>