<template>
  <div class="login-container" v-if="!isAuthenticated" >
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" id="username" v-model="credentials.username" required>
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="credentials.password" required>
      </div>

      <button type="submit">Login to account</button>
    </form>
  </div>
    <div v-else>
    <p>You are already logged in.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'UserLogin',
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      }
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isDriver']), // Геттер из Vuex
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.credentials)
        .then(() => {
          this.$router.push('/'); // Перенаправление на главную страницу после входа
        })
        .catch(error => {
          console.error('Ошибка входа', error);
          // Обработка ошибок входа
        });
    }
  }
};
</script>

<style>
.login-container {
  /* Стили для контейнера страницы входа */
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
}
</style>