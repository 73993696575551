<template>
  <div class="taxi-order-container">
    <h1>Заказ такси</h1>
    <form @submit.prevent="submitOrder">
      <div class="form-group">
        <label for="pickup-location">Departure point</label>
        <input type="text" id="pickup-location" v-model="order.pickupLocation" required>
      </div>

      <div class="form-group">
        <label for="dropoff-location">Destination</label>
        <input type="text" id="dropoff-location" v-model="order.dropoffLocation" required>
      </div>

      <div class="form-group">
        <label for="pickup-date">departure date</label>
        <input type="date" id="pickup-date" v-model="order.pickupDate" required>
      </div>

      <div class="form-group">
        <label for="pickup-time">Departure time</label>
        <input type="time" id="pickup-time" v-model="order.pickupTime" required>
      </div>

      <button type="submit">Send request</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'FutureTaxiorder',
  data() {
    return {
      order: {
        pickupLocation: '',
        dropoffLocation: '',
        pickupDate: '',
        pickupTime: '',
      }
    };
  },
  methods: {
    submitOrder() {
      // Логика отправки заказа такси
      console.log('Заказ такси:', this.order);
      // Отправить данные заказа, например, через API
    }
  }
};
</script>

<style>
.taxi-order-container {
  /* Стили для контейнера */
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
}
</style>