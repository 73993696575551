<template>
  <nav>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li v-if="isAuthenticated"><router-link to="/ride/create">Book a trip</router-link></li>
      <li v-if="!isAuthenticated"><router-link to="/register">Registration</router-link></li>
      <li v-if="!isAuthenticated"><router-link to="/login">Login</router-link></li>
      <li v-if="isDriver"><router-link to="/driver/active">NOW ACTIVE TRIP</router-link></li>
      <!-- <li><router-link to="/driver/list">List of drivers</router-link></li> -->
      <!-- Additional navigation links -->
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppNav',
  computed: {
    ...mapGetters(['isAuthenticated', 'isDriver']), // Геттер из Vuex
  },
  data() {
    return {
      items: []
    };
  },
};
</script>

<style>
nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}
</style>