<template>
  <footer>
    <p>&copy; {{ currentYear }} Jewel City Rides.</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style>
footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
}
</style>