import axios from 'axios';
import store from '@/store'; // Импортируйте ваш Vuex store

const API = axios.create({
  // baseURL: 'http://localhost:8000/'
  baseURL: 'https://api.clusterhack.dev/'
});

// Добавление интерсептора запроса
API.interceptors.request.use(function (config) {
  let token = store.state.authToken;
  if (!token) {
    token = localStorage.getItem('jwt'); // Получение токена из localStorage, если он не в Vuex
  }
  console.log('Токен в интерсепторе:', token); // Проверка токена
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


API.interceptors.response.use(response => {
  // Обработка успешного ответа
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // Очистка токена и редирект на страницу входа
    store.commit('clearAuthToken');
    localStorage.removeItem('jwt');
    // router.push('/login'); // Используйте правильный путь к вашей странице входа
  }
  return Promise.reject(error);
});

export default API;


