<template>
  <div class="taxi-order-page" v-if="isAuthenticated">
    <h1>Taxi ordering</h1>

    <!-- Address form -->
    <div class="address-inputs">
      <input type="text" v-model="pickupAddress" @input="() => fetchAddressSuggestions('pickupAddress')" placeholder="Departure address">
        <ul v-if="pickupAddressSuggestions.length">
          <li v-for="suggestion in pickupAddressSuggestions" :key="suggestion.place_id" @click="selectAddress('pickupAddress', suggestion)">
            {{ suggestion.display_name }}
          </li>
        </ul>

      <div id="pickup-map" class="map-container"></div>

      <input type="text" v-model="dropoffAddress" placeholder="Destination address" @input="() => fetchAddressSuggestions('dropoffAddress')">

      <ul v-if="dropoffAddressSuggestions.length">
        <li v-for="suggestion in dropoffAddressSuggestions" :key="suggestion.place_id" @click="selectAddress('dropoffAddress', suggestion)">
          {{ suggestion.display_name }}
        </li>
      </ul>

      <!-- Map for selecting a landing point -->
      <div id="dropoff-map" class="map-container"></div>
    </div>

    <!-- Map to select location -->
    <!-- <div id="" class="map-container"></div> -->

    
    <button @click="submitOrder" :disabled="!canSubmitOrder">Order a taxi</button>
  </div>
  <div v-else>
    <p>You are not authorized.</p>
  </div>
</template>






<script>
import L from 'leaflet';
import axios from 'axios';
import API from '@/api'; 
import { mapGetters } from 'vuex';

export default {
  name: 'TaxiOrder',
  data() {
    return {
      map: null,
      pickupAddress: '',
      dropoffAddress: '',
      pickupAddressSuggestions: [],
      dropoffAddressSuggestions: [],
      pickupCoordinates: null,
      dropoffCoordinates: null,
      pickupMap: null,
      dropoffMap: null,
    };
  },
  mounted() {
    // this.initMap();
    this.$nextTick(() => {
      this.initMaps();
    });
    this.$store.dispatch('checkToken');
  },

  computed: {
    canSubmitOrder() {
      return this.pickupAddress && this.dropoffAddress &&
             this.pickupCoordinates && this.dropoffCoordinates;
    },
    ...mapGetters(['isAuthenticated', 'isDriver']), 
  },
  methods: {
    initMapsOld() {
        // Инициализация карты для точки посадки
        this.pickupMap = L.map('pickup-map').setView([34.0522, -118.2437], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(this.pickupMap);

        // Добавление обработчика клика на карту посадки
        this.pickupMap.on('click', (e) => {
          if (this.pickupMarker) {
            this.pickupMap.removeLayer(this.pickupMarker);
          }
          this.pickupMarker = L.marker(e.latlng).addTo(this.pickupMap);
          this.pickupCoordinates = [e.latlng.lat, e.latlng.lng];
          this.reverseGeocode(e.latlng, 'pickupAddress');
        });

        // Инициализация карты для точки высадки
        this.dropoffMap = L.map('dropoff-map').setView([34.0522, -118.2437], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(this.dropoffMap);

        // Добавление обработчика клика на карту высадки
        this.dropoffMap.on('click', (e) => {
          if (this.dropoffMarker) {
            this.dropoffMap.removeLayer(this.dropoffMarker);
          }
          this.dropoffMarker = L.marker(e.latlng).addTo(this.dropoffMap);
          this.dropoffCoordinates = [e.latlng.lat, e.latlng.lng];
          this.reverseGeocode(e.latlng, 'dropoffAddress');
        });
      },
    initMaps() {
      // Проверка наличия элемента DOM для карты посадки
      if (document.getElementById('pickup-map')) {
        this.pickupMap = L.map('pickup-map').setView([34.0522, -118.2437], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(this.pickupMap);

        this.pickupMap.on('click', (e) => {
          // Удаление предыдущего маркера, если он существует
          if (this.pickupMarker) {
            this.pickupMap.removeLayer(this.pickupMarker);
          }
          this.pickupMarker = L.marker(e.latlng).addTo(this.pickupMap);
          this.pickupCoordinates = [e.latlng.lat, e.latlng.lng];
          this.reverseGeocode(e.latlng, 'pickupAddress');
        });
      }

      // Проверка наличия элемента DOM для карты высадки
      if (document.getElementById('dropoff-map')) {
        this.dropoffMap = L.map('dropoff-map').setView([34.0522, -118.2437], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(this.dropoffMap);

        this.dropoffMap.on('click', (e) => {
          if (this.dropoffMarker) {
            this.dropoffMap.removeLayer(this.dropoffMarker);
          }
          this.dropoffMarker = L.marker(e.latlng).addTo(this.dropoffMap);
          this.dropoffCoordinates = [e.latlng.lat, e.latlng.lng];
          this.reverseGeocode(e.latlng, 'dropoffAddress');
        });
      }
    },
    submitOrder() {
      // Подготовка данных заказа
      const orderData = {
        start_location: JSON.stringify(this.pickupCoordinates),
        end_location: JSON.stringify(this.dropoffCoordinates),
        start_name_location: this.pickupAddress,
        end_name_location: this.dropoffAddress,
        // ... другие данные заказа, если необходимо
      };

      // Отправка запроса на сервер
      this.submitTaxiOrder(orderData)
    },
    async submitTaxiOrder(orderData) {
      try {
        const response = await API.post('api/rides/create/', orderData);
        // Обработка успешного заказа
        this.$router.push('/ride/waiting'); // Перенаправление на страницу ожидания поездки
        return response.data;
      } catch (error) {
        console.error('Ошибка при заказе такси:', error);
        throw error;
      }
    },
    fetchAddressSuggestions(addressType) {
      const address = this[addressType];
      if (!address) {
        this[`${addressType}Suggestions`] = [];
        return;
      }

        axios.get(`https://us1.locationiq.com/v1/search?key=pk.6eef9d2642b61fe69b21cfdf58afa4f8&q=${address}&format=json`)
        .then(response => {
          this[`${addressType}Suggestions`] = response.data;
        })
        .catch(error => {
          console.error('Ошибка запроса предложений адреса:', error);
          this[`${addressType}Suggestions`] = [];
        });
    },
    selectAddressOld(addressType, suggestion) {
      this[addressType] = suggestion.display_name;
      this[`${addressType}Suggestions`] = [];
      // Вы можете также сохранить другие данные о выбранном адресе
    },
    selectAddress(addressType, suggestion) {
      this[addressType] = suggestion.display_name;
      this[`${addressType}Suggestions`] = [];

      // Сохранение координат
      if (addressType === 'pickupAddress') {
        this.pickupCoordinates = [suggestion.lat, suggestion.lon];
        // Обновление маркера на карте посадки
        this.updateMapMarker(this.pickupMap, suggestion, 'pickupMarker');
      } else if (addressType === 'dropoffAddress') {
        this.dropoffCoordinates = [suggestion.lat, suggestion.lon];
        // Обновление маркера на карте высадки
        this.updateMapMarker(this.dropoffMap, suggestion, 'dropoffMarker');
      }
    },
    reverseGeocode(latlng, addressType) {
      axios.get(`https://us1.locationiq.com/v1/reverse?key=pk.6eef9d2642b61fe69b21cfdf58afa4f8&lat=${latlng.lat}&lon=${latlng.lng}&format=json`)
        .then(response => {
          this[addressType] = response.data.display_name;
        })
        .catch(error => {
          console.error('Ошибка обратного геокодирования:', error);
        });
    },
    
    updateMapMarker(map, coordinates, markerName) {
      if (map && map.removeLayer) {
        if (this[markerName]) {
          map.removeLayer(this[markerName]);
        }
        this[markerName] = L.marker(coordinates).addTo(map);
        map.setView(coordinates, 13);
      }
    },
    // ... другие методы
  
  },
  
};
</script>

<style>
.map-container {
  height: 400px;
  margin-top: 20px;
  display: block;

}

</style>









