<template>
  <div class="ride-completion">
    <h1>Завершение поездки</h1>
    <div v-if="rideDetails">
      <h2>Trip details</h2>
      <p>From: {{ rideDetails.pickup_location }}</p>
      <p>To: {{ rideDetails.dropoff_location }}</p>
      <p>Travel time: {{ rideDetails.duration }}</p>
      <p>Coast: {{ rideDetails.cost }}</p>

      <!-- Opportunity to leave feedback -->
      <div class="feedback-section">
        <h3>Leave a review about the passenger</h3>
        <textarea v-model="feedback" placeholder="your feedback"></textarea>
        <button @click="submitFeedback">Post a review</button>
      </div>

      <!-- Move to next order -->
      <button @click="findNextRide">Find next order</button>
    </div>
    <div v-else>
      <p>Uploading trip data...</p>
    </div>
  </div>
</template>

<script>
import API from '@/api';

export default {
  name: 'RideCompletion',
  data() {
    return {
      rideDetails: null,
      feedback: ''
    };
  },
  created() {
    this.fetchRideDetails();
  },
  methods: {
    async fetchRideDetails() {
      // Замените 'rideId' на реальный идентификатор поездки
      const rideId = this.$route.params.rideId;
      try {
        const response = await API.get(`api/rides/${rideId}/details`);
        this.rideDetails = response.data;
      } catch (error) {
        console.error('Ошибка при получении данных о поездке:', error);
      }
    },
    async submitFeedback() {
      // Логика отправки отзыва
      console.log('Отправленный отзыв:', this.feedback);
      // Отправка отзыва на сервер
    },
    findNextRide() {
      // Логика для поиска следующего заказа
      this.$router.push('/driver/available-rides');
    }
  }
};
</script>

<style>
.ride-completion {
  /* Ваши стили */
}
</style>