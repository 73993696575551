<template>
  <div>
    <h2>Upcoming Trips</h2>
    <div v-if="activeRides.length === 0">
      <p>There are no active trips at the moment.</p>
    </div>
    <div v-else>
      <ul>
        <li v-for="ride in activeRides" :key="ride.id">
          - Drive #{{ ride.id }} - Status: {{ ride.status }} ({{ ride.created_at }})
          <br>
          start : {{ ride.start_name_location }}  ( {{ ride.start_location }} )
          <br>
          end : {{ ride.end_name_location }}  ( {{ ride.end_location }} )
          <br>
          ------------------------
          <br>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import API from '@/api'; // Убедитесь, что путь к API.js верный

export default {
  name: 'RideWWaiting',
  data() {
    return {
      activeRides: [],
      intervalId: null
    };
  },
  created() {
    this.fetchActiveRides();
    this.intervalId = setInterval(this.fetchActiveRides, 10000); // Периодическое обновление каждые 10 секунд
  },
  methods: {
    async fetchActiveRides() {
      try {
        const response = await API.get('api/rides/active/');
        this.activeRides = response.data;
      } catch (error) {
        console.error('Ошибка при получении активных поездок:', error);
        this.activeRides = [];
      }
    }
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Очистка интервала при уничтожении компонента
    }
  }
};
</script>





