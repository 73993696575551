<template>
  <div v-if="isAuthenticated">
    <h1>You are already registered</h1>
    <!-- Additional content for successful registration -->
  </div>
  <div v-else-if="registrationSuccess">
  <h1>registration completed successfully!</h1>
  <!-- Additional content for successful registration -->
  </div>
  <div v-else>
    <h1>Registration</h1>
    <form @submit.prevent="register">
      <input type="text" v-model="user.username" placeholder="Username">
      <input type="password" v-model="user.password" placeholder="Password">
      <button type="submit">Registration</button>
    </form>
  </div>
</template>

<script>
import API from '@/api';

export default {
  name: 'UserRegistration',
  data() {
    return {
      user: {
        username: '',
        password: ''
      },
      registrationSuccess: false
    };
  },
  methods: {
    register() {
      console.log('Отправляемые данные:', this.user);
      API.post('/api/register/', this.user)
        .then(response => {
          localStorage.setItem('jwt', response.data.token);
          this.registrationSuccess = true;
        })
        .catch(error => {
          console.error('Ошибка регистрации', error);
        });
    }
  }
};
</script>